import { getDefaultState } from 'store/initialState';
import { OptionUser } from '_config';
import {
  APOLLO_NETWORK_ERROR,
  APOLLO_GRAPHQL_ERROR,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  POITYPE_PAYSLIP_UPLOAD,
  POITYPE_DOC_UPLOAD,
  POI_CHECK_REQUIRED,
} from 'store';
import {
  PENDING_CUSTOMER_ACCEPTANCE,
  PENDING,
  APPROVED,
  DECLINED,
  REFERRED,
  CONDITIONALLY_APPROVED,
  PROOF_OF_ADDRESS,
  PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING,
} from 'store/constants';
import { isFeatureOn } from 'featureToggles';

const personalDetails = {
  valid: {
    title: 'MR',
    firstName: 'Rafael',
    middleName: '',
    lastName: 'Madams',
    dateOfBirth: {
      day: '25',
      month: '12',
      year: '1973',
    },
  },
};

const contactDetails = {
  valid: {
    mobileNumber: '0201234567',
    emailAddress: 'mock@email.com',
    confirmEmail: 'mock@email.com',
  },
};

const latitudeId = {
  valid: {
    latitudeId: 'mock@email.com',
    password: '12Abc$^&',
  },
};

const verification = {
  valid: {
    verificationCode: '123456',
  },
};

const password = {
  valid: {
    password: 'Abc123',
  },
};

const otherDetails = {
  valid: {
    residentialStatus: 'HOME_OWNER',
    lengthOfCurrentResidency: {
      years: '3',
      months: '0',
    },
    isPostalSameWithResidential: 'yes',
    hasNonDomesticPreviousAddress: false,
    currentAddress: {
      useManualAddress: false,
      address: {
        addressType: 'STREET_ADDRESS',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '49',
        streetName: 'Auckland',
        streetType: 'Road',
        suburb: 'Manurewa',
        city: 'Auckland',
        postcode: '1010',
        countryCode: 'NZ',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        addressType: '',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        countryCode: 'NZ',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        addressType: '',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        countryCode: 'NZ',
      },
    },
  },
};
const extraDetails = {
  valid: {
    relationshipStatus: 'MARRIED_DEFACTO',
    numberOfDependents: '0',
    idType: 'NZ_DRIVERS_LICENCE',
    licenceNumber: 'AB123456',
    idNumber: '',
    version: '453',
    expiryDate: {
      day: '01',
      month: '01',
      year: '2030',
    },
    nzResidencyNumber: '',
    issuingCountry: '',
  },
};

// TODO update this with correct value
// https://di.latitudefinancial.com/jira/browse/NZP-518
const employmentDetails = {
  valid: {
    occupation: 'IT',
    industry: 'COMPUTER_STAFF',
    employerName: 'Latitude',
    lengthOfTimeAtCurrentEmployer: {
      years: '1',
      months: '0',
    },
    lengthOfUnemployment: {
      years: '',
      months: '',
    },
    income: {
      amount: '4000',
      frequency: 'monthly',
    },
    employmentStatus: 'PERMANENT',
    hasOtherIncome: 'yes',
    otherEmploymentStatus: 'RENTAL_INCOME',
    otherIncome: {
      amount: '1000',
      frequency: 'monthly',
    },
    expectedIncomeChange: 'no',
    incomeAdjustmentRequired: 'no',
    conditionalStop: 'no',
  },
};
const expensesDetails = {
  valid: {
    isExpensesShared: 'no',
    isRevolvingOrOffsetMortgage: 'no',
    facilityLimit: '0',
    // Your residential expenses
    primaryPropertyMortgageRentBoard: {
      amount: '2000',
      frequency: 'monthly',
    },
    secondaryPropertyMortgageRentBoard: {
      amount: '0',
      frequency: 'monthly',
    },
    propertyRates: {
      amount: '0',
      frequency: 'monthly',
    },
    propertyUtilitiesOther: {
      amount: '0',
      frequency: 'monthly',
    },
    // Your living expenses
    foodGroceriesLifestyleEntertainment: {
      amount: '0',
      frequency: 'monthly',
    },
    healthLifeInsurance: {
      amount: '200',
      frequency: 'monthly',
    },
    generalInsurance: {
      amount: '0',
      frequency: 'monthly',
    },
    transport: {
      amount: '200',
      frequency: 'monthly',
    },
    educationChildcare: {
      amount: '0',
      frequency: 'monthly',
    },
    personalFitnessMedical: {
      amount: '50',
      frequency: 'monthly',
    },
    other: {
      amount: '0',
      frequency: 'monthly',
    },
  },
};

const additionalDebtsDetails = {
  valid: {
    hasOtherDebts: 'no',
    homeLoans: '',
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: 'no',
    payLumpSumWithExistingSaving: '',
  },
};

const creditLimitDetails = {
  valid: {
    creditLimitSource: OptionUser,
    creditLimit: '5000',
  },
  declined: {
    creditLimit: '',
  },
  referred: {
    creditLimit: '',
  },
  conditionallyApproved: {
    creditLimit: '',
  },
};

const preferredCreditLimitDetails = {
  valid: {
    creditLimit: '5000',
  },
  declined: {
    creditLimit: '2000',
  },
  referred: {
    creditLimit: '2000',
  },
  conditionallyApproved: {
    creditLimit: '2000',
  },
};

const acceptance = {
  valid: {
    accepted: true,
  },
};

const complete = {
  approved: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          applicationReason: 'EXPENSES',
          consentGiven: true,
        }
      : {
          applicationReason: 'EXPENSES',
          consentGiven: true,
        },
    contactDetails: contactDetails.valid,
    latitudeId: latitudeId.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    creditLimitDetails: creditLimitDetails.valid,
    preferredCreditLimitDetails: preferredCreditLimitDetails.valid,
    acceptance: acceptance.valid,
  },
  declined: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          applicationReason: 'EXPENSES',
          consentGiven: true,
        }
      : {
          applicationReason: 'EXPENSES',
          consentGiven: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    creditLimitDetails: creditLimitDetails.declined,
    preferredCreditLimitDetails: preferredCreditLimitDetails.declined,
  },
  referred: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          applicationReason: 'EXPENSES',
          consentGiven: true,
        }
      : {
          applicationReason: 'EXPENSES',
          consentGiven: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    creditLimitDetails: creditLimitDetails.referred,
    preferredCreditLimitDetails: preferredCreditLimitDetails.referred,
  },
  conditionallyApproved: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          applicationReason: 'EXPENSES',
          consentGiven: true,
        }
      : {
          applicationReason: 'EXPENSES',
          consentGiven: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    creditLimitDetails: creditLimitDetails.conditionallyApproved,
    preferredCreditLimitDetails: preferredCreditLimitDetails.approved,
  },
};

export const autofill = {
  overview: {
    'complete:approved': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: APPROVED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'complete:declined': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: DECLINED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.declined,
    },
    'complete:referred': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: REFERRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.referred,
    },
    'complete:INCOME_PAGE': {
      activeStep: '/verify-income',
      applicationSubmitted: true,
      applicationStatus: PENDING,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'complete:referred-upload-payslips': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: REFERRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.referred,
    },
    'complete:referred-upload-documents': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: REFERRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.referred,
    },
    'complete:conditionally-approved-poa': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-aml': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_INCOME,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-poa-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-aml-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-upload-doc-poa': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-approved-upload-doc-aml': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-payslips-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-payslips-aml-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-payslips-poa-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-documents-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-documents-aml-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-documents-poa-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'go-to:processing': {
      activeStep: '/processing',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'go-to:commitments': {
      activeStep: '/commitments',
      applicationStatus: '',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: {
        ...complete.approved,
        acceptance: { accepted: false },
      },
    },
    'final-step:pca': {
      activeStep: '/acceptance',
      applicationStatus: PENDING_CUSTOMER_ACCEPTANCE,
      applicationSubmitted: true,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: {
        ...complete.approved,
        acceptance: { accepted: false },
      },
      creditLimitApproved: 4000,
    },
    'final-step:error': {
      activeStep: '/credit-limit',
      applicationStatus: PENDING,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: {
        type: APOLLO_GRAPHQL_ERROR,
        error: {
          graphQLErrors: {
            message: 'address is invalid',
          },
        },
      },
      acquisition: {
        ...complete.approved,
        acceptance: { accepted: false },
      },
    },
    'final-step:error-with-retry': {
      activeStep: '/credit-limit',
      applicationStatus: PENDING,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: {
        type: APOLLO_NETWORK_ERROR,
        error: {
          networkError: {
            bodyText: 'Bad Gateway',
          },
        },
      },
      acquisition: {
        ...complete.approved,
        acceptance: { accepted: false },
      },
    },
    'final-step:error-with-retry-application-id': {
      activeStep: '/credit-limit',
      applicationStatus: PENDING,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: {
        type: APOLLO_NETWORK_ERROR,
        error: {
          networkError: {
            bodyText: 'Bad Gateway',
          },
        },
      },
      acquisition: {
        ...complete.approved,
        acceptance: { accepted: false },
      },
    },
  },
  contactDetails,
  latitudeId,
  verification,
  password,
  personalDetails,
  otherDetails,
  extraDetails,
  expensesDetails,
  employmentDetails,
  additionalDebtsDetails,
  creditLimitDetails: { valid: creditLimitDetails.valid },
  preferredCreditLimitDetails: { valid: preferredCreditLimitDetails.valid },
  acceptance,
  reset: getDefaultState(),
};
